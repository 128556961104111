@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Satoshi', sans-serif;
}

.dark {
  background: linear-gradient(to right, #0A181C, #032826);
}

.light {
  background: #FFFFFF;
}
